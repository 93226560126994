<template>
  <div id="content" style="flex: 1 1 auto; overflow-y: auto; min-height: 0px;">
    <div id="loading" v-if="this.$store.state.statistics.loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header class="pb-3">
        <h1>{{ $t('message.statistics_document_chart') }} </h1>
      </header>
      <div class="container-fluid">
        <div class="row">
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-select v-model="selectedType" :items="types" item-text="label" item-value="key" label="Type of Graphiques"
                          :rules="typeRules" required
                          persistent-hint return-object single-line></v-select>
              </v-col>
              <!--  From -->
              <v-col cols="12" sm="12" md="3">
                <v-menu v-if="selectedType && selectedType.key !== 'latest'" ref="menuFrom" v-model="menuFrom" :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y max-width="290px"
                        min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mb-2"
                                  persistent-hint
                                  label="De"
                                  prepend-icon="mdi-calendar"
                                  autocomplete="off"
                                  :value="from"
                                  readonly
                                  @focus="datepicker = true"
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="dateRules"
                                  required>

                    </v-text-field>
                  </template>
                  <v-date-picker v-if="datepicker" v-model="from" no-title for></v-date-picker>
                </v-menu>
              </v-col>
              <!--  To   -->
              <v-col cols="12" sm="12" md="3">
                <v-menu v-if="selectedType && selectedType.key !== 'latest'" ref="menuTo" v-model="menuTo" :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y max-width="290px"
                        min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="mb-2"
                                  persistent-hint
                                  label="à"
                                  prepend-icon="mdi-calendar"
                                  autocomplete="off"
                                  :value="to"
                                  readonly
                                  @focus="datepicker = true"
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="dateRules"
                                  required>
                    </v-text-field>
                  </template>
                  <v-date-picker v-if="datepicker" v-model="to" no-title for></v-date-picker>
                </v-menu>
              </v-col>

              <!--  button      -->
              <v-col cols="12" sm="12" md="2" class="d-flex align-items-center">
                <v-btn elevation="2" class="primary v-btn text-white" @click="searchForm">
                  Valider
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>

        <div class="row">
          <div class="col-md-12"></div>
          <div class="col-md-2 col-sm-1"></div>
          <div class="col-md-8 col-sm-10">
            <canvas ref="documentsChart"></canvas>
          </div>
          <div class="col-md-2 col-sm-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DefaultLayout from '../../../layouts/DefaultLayout.vue'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(ChartDataLabels)

export default {
  name: 'DocumentChart',
  components: {},
  data: () => ({
    chart: null,
    datepicker: false,
    from: null,
    to: null,
    selectedType: null,
    ranges: null,
    menuFrom: false,
    menuTo: false,
    types: [
      { key: 'latest', label: 'Latest' },
      { key: 'interval', label: 'Interval' }
    ],
    valid: false,
    errors: [],
    dateRules: [
      v => !!v || 'Date is required'
    ],
    typeRules: [
      value => {
        if (value) return true

        return 'Type is required.'
      }
    ]
  }),
  computed: {
    loading: {
      get: function () {
        return this.$store.state.statistics.loading
      },
      set: function (value) {
        this.$store.commit('storageStore/setLoading', value)
      }
    }
  },
  mounted () {
    this.selectedType = { key: 'latest', label: 'Latest' }
  },
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.$store.dispatch('statistics/getData',
      {
        from: this.from,
        to: this.to,
        chart: 'documents',
        type: 'latest',
        ranges: this.ranges
      })
  },
  methods: {
    formatLatest (info) {
      info.text = 'Nombre des Documents ' + this.$options.filters.dateFormat(this.$store.state.statistics.data.date)
      info.backgroundColor = [
        '#25519D',
        '#ea6b00',
        '#C4C4C4'
      ]
      info.hoverBackgroundColor = [
        '#112549',
        '#833e02',
        '#676767'
      ]
      info.data = []
      info.labels = []

      for (const [key, value] of Object.entries(this.$store.state.statistics.data.data)) {
        switch (key) {
          case 'employees_total':
            info.labels.push('Fiducial')
            info.data.push(value)
            break
          case 'clients_total':
            info.labels.push('Clients')
            info.data.push(value)
            break
          case 'budget_code_total':
            info.labels.push('Budget Code')
            info.data.push(value)
            break
        }
      }
      return info
    },
    formatInterval (info) {
      info.text = 'Nombre des Documents '
      info.data = []
      info.labels = []
      info.backgroundColor = [
        '#25519D',
        '#25519D',
        '#ea6b00',
        '#ea6b00',
        '#C4C4C4',
        '#C4C4C4'
      ]
      info.hoverBackgroundColor = [
        '#112549',
        '#112549',
        '#833e02',
        '#833e02',
        '#676767',
        '#676767'
      ]

      const employeesTemp = {}
      employeesTemp.labels = []
      employeesTemp.data = []
      const clientsTemp = {}
      clientsTemp.labels = []
      clientsTemp.data = []
      const budgetCodeTemp = {}
      budgetCodeTemp.labels = []
      budgetCodeTemp.data = []

      this.$store.state.statistics.data.forEach((statistic, i) => {
        if ((this.$store.state.statistics.data.length - 1) === i) {
          info.text += this.$options.filters.dateFormat(statistic.date)
        } else {
          info.text += this.$options.filters.dateFormat(statistic.date) + ' - '
        }

        this.fillLabelAndData(employeesTemp, statistic, 'employees_total')
        this.fillLabelAndData(clientsTemp, statistic, 'clients_total')
        this.fillLabelAndData(budgetCodeTemp, statistic, 'budget_code_total')
      })

      info.labels = info.labels.concat(employeesTemp.labels)
      info.labels = info.labels.concat(clientsTemp.labels)
      info.labels = info.labels.concat(budgetCodeTemp.labels)
      info.data = info.data.concat(employeesTemp.data)
      info.data = info.data.concat(clientsTemp.data)
      info.data = info.data.concat(budgetCodeTemp.data)

      return info
    },
    fillLabelAndData (ret, statistic, label) {
      for (const [key, value] of Object.entries(statistic.data)) {
        if (key === label) {
          const lbl = key === 'employees_total' ? 'Fiducial' : key === 'budget_code_total' ? 'Budget Code' : 'Clients'
          ret.labels.push(lbl + ' - ' + this.$options.filters.dateFormat(statistic.date))
          ret.data.push(value)
        }
      }

      return ret
    },
    formatData () {
      const info = {}

      if (this.selectedType.key === 'latest') {
        return this.formatLatest(info)
      }

      return this.formatInterval(info)
    },
    loadChart () {
      if (this.chart) {
        this.chart.destroy()
      }

      const info = this.formatData()

      Chart.defaults.elements.arc.hoverBorderColor = 'white'
      Chart.defaults.datasets.doughnut.cutout = '50%'
      Chart.register(ChartDataLabels)

      /* 10% of padding */
      const paddingMax = Math.abs(Math.max(...info.data) * 0.1 + Math.max(...info.data))

      const data = {
        labels: info.labels,
        datasets: [
          {
            data: info.data,
            backgroundColor: info.backgroundColor,
            hoverBackgroundColor: info.hoverBackgroundColor
          }
        ]
      }

      this.chart = new Chart(this.$refs.documentsChart, {
        type: 'bar',
        data: data,
        options: {
          scales: {
            y: {
              max: paddingMax
            }
          },
          responsive: true,
          elements: {},
          plugins: {
            title: {
              text: info.text,
              display: true,
              padding: {
                top: 10,
                bottom: 30
              }
            },
            legend: {
              display: false
            },
            tooltip: {
              enabled: true
            },
            datalabels: {
              // Position of the labels
              // (start, end, center, etc.)
              anchor: 'end',
              // Alignment of the labels
              // (start, end, center, etc.)
              align: 'end',
              // Color of the labels
              color: 'black',
              font: {
                weight: 'bold'
              },
              formatter: function (value, context) {
                // Display the actual data value
                return value
              }
            }
          }
        }
      })
    },
    searchForm: function () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('statistics/getData',
          {
            from: this.from,
            to: this.to,
            chart: 'documents',
            ranges: this.ranges,
            type: this.selectedType ? this.selectedType.key : null
          })
      }
    }
  },
  watch: {
    loading () {
      if (!this.$store.state.statistics.loading) {
        this.loadChart()
      }
    }
  }
}
</script>
